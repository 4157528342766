<template>
  <div class="tree-menu">
    <div :style="indent" :class="showChildren ? 'current' : ''">
      <router-link
        :to="{ name: 'Category', params: { seoName: category.seoName } }"
      >
        {{ $options.filters.titleCase(label) }}
      </router-link>
      <span
        v-if="nodes && nodes.length > 0"
        class="arrows"
        @click="toggleChildren"
      >
      </span>
    </div>

    <transition
      appear
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="showChildren">
        <tree-menu
          :key="index"
          v-for="(node, index) in nodes"
          :category="node"
          :nodes="node.childCategory"
          :label="node.categoryName"
          :depth="depth + 1"
        />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    index: Number,
    label: String,
    nodes: Array,
    depth: Number,
    category: Object,
  },
  name: 'tree-menu',
  computed: {
    indent() {
      return {
        transform: `translate(${this.depth * 5}px)`,
        display: 'flex',
        width: `calc(100% - ${this.depth * 5}px)`,
      }
    },
  },
  data() {
    return { showChildren: false }
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren
    },
  },
}
</script>

<style lang="scss">
.arrows {
  flex: 0 0 30px;
  cursor: pointer;
  &:before {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    border-bottom: 2px solid rgba(49, 65, 84, 0.5);
    border-right: 2px solid rgba(49, 65, 84, 0.5);
    position: relative;
    top: 50%;
    left: 7px;
    transform: translateY(-50%) rotate(-45deg);
    @media (min-width: 1681px) {
      width: 12px;
      height: 12px;
    }
  }
}
.current {
  & > .arrows:before {
    transform: translateY(-50%) rotate(45deg);
  }
}
</style>
